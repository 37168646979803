import { useCallback, useEffect, useMemo, useState } from 'react';
import { catalogStore } from '../stores';
import countriesCode from '../utils/countriesCode.json';
import axios from 'axios';
import useGeoLocation from './useGeolocation';

const useCurrencySwitcher = setCurrencyCode => {
	const {
		locationInfo,
		setLocationInfo,
		setExchangeRates,
		exchangeRates,
		shippingInfo,
		setNewShippingInfo,
		exchangeRateTimes,
		setExchangeRateTimes,
		primaryLocation,
	} = catalogStore;
	const [showList, setShowList] = useState(false);
	const [selectedCountry, setSelectedCountry] = useState({});
	const [filteredCountryFlags, setFilteredCountryFlags] = useState([]);

	//exchange api call

	const getExchangeRates = async () => {
		try {
			const res = await axios.get(
				'https://v6.exchangerate-api.com/v6/523e84fd47d9082207db42ab/latest/USD'
			);
			if (res.status === 200) {
				const nextUpdateTime = new Date(
					res?.data?.time_next_update_utc
				).getTime();
				const currentTime = Date.now();

				const exchangeRateTimes = {
					nextUpdateTime,
					currentTime,
				};

				setExchangeRateTimes(exchangeRateTimes);
				return setExchangeRates(res.data.conversion_rates);
			}
		} catch (error) {
			console.error('Error fetching exchange rates:', error);
		}
	};

	//Gets exchange rates

	useEffect(() => {
		const checkAndCallAPI = () => {
			const storedNextUpdateTime = exchangeRateTimes?.nextUpdateTime;

			const storedLastCallTime = exchangeRateTimes?.currentTime;
			const currentTime = Date.now();

			const eightHoursInMillis = 8 * 60 * 60 * 1000;

			if (
				isNaN(storedNextUpdateTime) ||
				isNaN(storedLastCallTime) ||
				currentTime >= storedNextUpdateTime ||
				currentTime - storedLastCallTime >= eightHoursInMillis
			) {
				getExchangeRates();
			}
		};

		checkAndCallAPI();
	}, [exchangeRateTimes]);

	//Get Location
	const { country } = useGeoLocation();

	const switchedToLocation = useMemo(() => {
		return {
			countryCode: country,
		};
	}, [country]);

	const countrySwitchHandler = location => {
		setSelectedCountry(location);
		setLocationInfo(location);
		if (location?.currencyCode === 'USD') {
			return setCurrencyCode('FUSD');
		}
		setCurrencyCode(location.currencyCode);
	};

	const getFlagImage = countryCode => {
		try {
			return require(`../assets/img/flags/${countryCode.toLowerCase()}.png`);
		} catch (err) {
			console.error(`Flag for ${countryCode} not found`);
			return null; // Or a default flag image
		}
	};

	const prioritizedCountries = useMemo(() => {
		return ['US', 'GB', 'CA', 'EU', 'NG'];
	}, []);

	const europeEntry = useMemo(() => {
		return {
			countryName: 'Europe',
			countryCode: 'EU',
			currencyCode: 'EUR',
			capital: '',
			population: '',
		};
	}, []);

	const countryFlags = useMemo(() => {
		const updateCountries = [...countriesCode, europeEntry];
		const priority = updateCountries.filter(country =>
			prioritizedCountries.includes(country.countryCode)
		);
		const remainingCountries = updateCountries.filter(
			country => !prioritizedCountries.includes(country.countryCode)
		);

		const countries = [...priority, ...remainingCountries];

		if (primaryLocation !== 'NG') {
			return countries
				.filter(x => x.countryCode !== 'NG')
				.map(country => {
					return {
						...country,
						flag: getFlagImage(country.countryCode),
					};
				});
		}

		return countries.map(country => {
			return {
				...country,
				flag: getFlagImage(country.countryCode),
			};
		});
	}, [europeEntry, primaryLocation, prioritizedCountries]);

	const switchedPrice = useCallback(
		(currencyCode, price) => {
			if (currencyCode === 'NGN') {
				return {
					currencyCode: currencyCode,
					price: price,
				};
			}

			return {
				currencyCode: locationInfo?.currencyCode,
				price: price * exchangeRates[locationInfo?.currencyCode],
			};
		},
		[exchangeRates, locationInfo?.currencyCode]
	);

	const newShippingInfo = useMemo(() => {
		if (
			switchedToLocation.countryCode === 'NG' ||
			!switchedToLocation.countryCode
		) {
			return { ...shippingInfo, currencyCode: 'NGN' };
		}
		if (switchedToLocation.countryCode === 'US') {
			return { ...shippingInfo, currencyCode: 'USD' };
		}

		const convertedShippingPrice = switchedPrice(
			'USD',
			shippingInfo?.shippingFee
		);
		const convertedFreeShippingPrice = switchedPrice(
			'USD',
			shippingInfo?.freeShippingForOrderAbove
		);

		return {
			shippingFee: convertedShippingPrice?.price,
			freeShippingForOrderAbove: convertedFreeShippingPrice?.price,
			currencyCode: convertedShippingPrice?.currencyCode,
		};
	}, [switchedToLocation.countryCode, shippingInfo, switchedPrice]);

	useEffect(() => {
		const conversionFn = () => {
			if (switchedToLocation.countryCode === 'NG') {
				setNewShippingInfo(newShippingInfo);
				setCurrencyCode('NGN');
			} else {
				setNewShippingInfo(newShippingInfo);
				setCurrencyCode('USD');
			}
		};

		conversionFn();
	}, [switchedToLocation, newShippingInfo]);

	const initialCurrencyData = () => {
		const location = countryFlags.filter(
			x => x.countryCode === primaryLocation
		)[0];

		if (locationInfo?.countryCode) {
			return setSelectedCountry(locationInfo);
		}
		setSelectedCountry(location);
		setLocationInfo(location);
	};

	useEffect(() => {
		if (primaryLocation) {
			initialCurrencyData();
		}
	}, [primaryLocation]);

	return {
		showList,
		setShowList,
		selectedCountry,
		countrySwitchHandler,
		countryFlags,
		filteredCountryFlags,
		setFilteredCountryFlags,
		switchedPrice,
	};
};

export default useCurrencySwitcher;
