import img_everything from '../assets/img/img_everything.png';
import img_solution from '../assets/img/img_solution.png';
import img_benefits from '../assets/img/img_benefits.png';
import img_payroll from '../assets/img/img_payroll.png';
import { isValidPhoneNumber } from 'libphonenumber-js';
import img_unlock from '../assets/img/img_unlock.png';
import constant from './constant';
import moment from 'moment';
import NgFlag from '../assets/img/nigeria-flag.png';
import IntFlag from '../assets/img/International-flag.jpg';
import { MimaToastUtil } from '../components';
import { StyleCatalogController } from '../controllers';

export const generateRandomColor = () => {
	var letters = '0123456789ABCDEF';
	var color = '#';

	for (var i = 0; i < 6; i++) {
		color += letters[Math.floor(Math.random() * 16)];
	}

	return color;
};

export const selectRandomColorByIndex = (index = 0) => {
	const options = ['#CCEDFF', '#E7D788', '#C1DAFF', '#97C1FF'];
	return options[index % options.length];
};

export const sortedDesc = array =>
	array.sort((objA, objB) => Number(objB.date) - Number(objA.date));

export const calculatePensionAmount = ({
	basicSalary,
	housingAllowance,
	transportAllowance,
}) => {
	return (basicSalary + housingAllowance + transportAllowance) * 0.08;
};

export const calculateNHFAmount = ({ basicSalary }) => {
	return basicSalary > 0 ? (2.5 * basicSalary) / 100 : 0;
};

export const calculateCRA = ({
	chargedPension,
	chargedNHF,
	grossPay,
	basicSalary,
	housingAllowance,
	transportAllowance,
}) => {
	const annualGross = grossPay * 12;
	const pension = chargedPension
		? calculatePensionAmount({
				basicSalary,
				housingAllowance,
				transportAllowance,
			}) * 12
		: 0;
	const nhf = chargedNHF ? calculateNHFAmount({ basicSalary }) * 12 : 0;
	let taxRelief = 0.01 * grossPay * 12;
	taxRelief = taxRelief < 200000 ? 200000 : taxRelief;
	const salaryRelief = 0.2 * (annualGross - pension - nhf);

	return { taxRelief, salaryRelief };
};

export const calculateWHTAmount = grossPay => {
	return (5 * grossPay) / 100;
};

export const calculatePAYEAmount = ({
	grossPay,
	chargedPension,
	chargedNHF,
	basicSalary,
	housingAllowance,
	transportAllowance,
}) => {
	let yearlyGrossPay = grossPay * 12;
	const { taxRelief, salaryRelief } = calculateCRA({
		chargedPension,
		chargedNHF,
		grossPay,
		basicSalary,
		housingAllowance,
		transportAllowance,
	});

	const nhf = calculateNHFAmount({ basicSalary });

	const pension = calculatePensionAmount({
		basicSalary,
		housingAllowance,
		transportAllowance,
	});
	const onePercentGross = 0.01 * yearlyGrossPay;

	yearlyGrossPay -= taxRelief + salaryRelief;
	yearlyGrossPay -= chargedNHF ? nhf * 12 : 0;
	yearlyGrossPay -= chargedPension ? pension * 12 : 0;

	if (yearlyGrossPay < onePercentGross) {
		return onePercentGross / 12;
	}

	let taxAmount = 0;
	let next300 = 0;
	let first500 = 0;
	let next500 = 0;
	let next16M = 0;
	let next32M = 0;

	if (yearlyGrossPay < 300000) return 0;
	const first300 = yearlyGrossPay - 300000;
	taxAmount += 21000;
	if (first300 === 0) return taxAmount / 12;
	if (first300 > 0) {
		next300 = first300 - 300000;
		if (next300 === 0) return (taxAmount + 33000) / 12;
		if (next300 < 0) return (taxAmount + first300 * 0.15) / 12;
		taxAmount += next300 > 0 ? 33000 : next300 * 0.11;
	}
	if (next300 > 0) {
		first500 = next300 - 500000;
		if (first500 === 0) return (taxAmount + 75000) / 12;
		if (first500 < 0) return (taxAmount + next300 * 0.15) / 12;
		taxAmount += 75000;
	}
	if (first500 > 0) {
		next500 = first500 - 500000;
		if (next500 === 0) return (taxAmount + 95000) / 12;
		if (next500 < 0) return (taxAmount + first500 * 0.19) / 12;
		taxAmount += 95000;
	}
	if (next500 > 0) {
		next16M = next500 - 1600000;
		if (next16M === 0) return (taxAmount + 336000) / 12;
		if (next16M < 0) return (taxAmount + next500 * 0.21) / 12;
		taxAmount += 336000;
	}
	if (next16M > 0) {
		next32M = next16M - 3200000;
		if (next32M === 0) return (taxAmount + next16M * 0.24) / 12;
		if (next32M < 0) return (taxAmount + next16M * 0.24) / 12;
		taxAmount += next32M * 0.24;
	}
	return taxAmount / 12;
};

export const calculateNetAmount = values => {
	let totalAmount = 0;
	let { grossPay } = values;
	totalAmount = grossPay;
	totalAmount += values.performanceBonus || 0;
	if (values.deductions?.tax)
		totalAmount -= calculatePAYEAmount({
			grossPay,
			chargedPension: values.deductions?.pension,
			chargedNHF: values.deductions?.nhf,
			basicSalary: values.basicSalary,
			housingAllowance: values.housingAllowance,
			transportAllowance: values.transportAllowance,
		});
	if (values.deductions?.wht) totalAmount -= calculateWHTAmount(grossPay);
	if (values.deductions?.pension)
		totalAmount -= calculatePensionAmount({
			basicSalary: values.basicSalary,
			housingAllowance: values.housingAllowance,
			transportAllowance: values.transportAllowance,
		});
	if (values.deductions?.nhf)
		totalAmount -= calculateNHFAmount({ basicSalary: values.basicSalary });
	if (values.otherDeductionAmount && values.otherDeductionAmount > 0)
		totalAmount -= values.otherDeductionAmount;
	return amountFormatter().format(totalAmount);
};

export const getInitials = (name = '') =>
	name
		.match(/(\b\S)?/g)
		.join('')
		.match(/(^\S|\S$)?/g)
		.join('')
		.toUpperCase();

export const isPhoneNumberValid = number => {
	return number?.length > 8 && isValidPhoneNumber(number);
};
export const currencies = ['NGN', 'USD', 'GBP', 'EUR'].map(curr => ({
	key: curr,
	value: curr,
}));

export const chargeSplitValues = ['CUSTOMER', 'MY BUSINESS', 'EQUAL SPLIT'].map(
	charge => ({
		key: charge,
		value: charge,
	})
);

export const amountFormatter = currency =>
	new Intl.NumberFormat('en-NG', {
		style: 'currency',
		currency: currency || 'NGN',
	});

export const amountFormatterWithoutCurrency = amount => {
	// Use toLocaleString to add commas for thousands separator
	return amount.toLocaleString('en-NG', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export const capitalizeFirstLetter = function toTitleCase(str) {
	return str.replace(/\w\S*/g, function (txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
	});
};

export const convertToTitleCase = str => {
	// Add a space before each uppercase letter, then capitalize the first letter
	return str
		.replace(/([A-Z])/g, ' $1') // Insert space before capital letters
		.replace(/^./, function (match) {
			return match.toUpperCase(); // Capitalize the first letter of the string
		});
};
// export const navigateToFirstRoute = permissions => {
// 	// const permissions = userStore.user?.permissions || [];
// 	const userPerm = [...new Set(permissions.map(perm => perm.module))];
// 	const flattenedSidebarData = SidebarData.flatMap(item => [
// 		item,
// 		...(item.subNav || []),
// 	]);
// 	const value = [
// 		...new Set(
// 			flattenedSidebarData.map(bar => userPerm.find(x => x === bar.module))
// 		),
// 	].filter(perm => perm !== undefined)[0];
// 	const { link } = flattenedSidebarData.find(bar => bar.module === value);
// 	return link;
// };

export function getMultipleRandom(arr, num) {
	const shuffled = [...arr].sort(() => 0.5 - Math.random());
	return shuffled.slice(0, num);
}

export const removeIdFromArray = (ids = [], id) => {
	const filterIds = ids.filter(x => x._id !== id);
	return filterIds;
};

export const addIdFromArray = (ids = [], id) => {
	ids.push(id);
	return ids;
};

export const groupTransactionsByParam = (transactions, groupBy = 'date') => {
	if (!transactions || !transactions.length || transactions.length === 0)
		return [];
	const formattedTransactions = [];
	const transactionsTitleSet = new Set(
		transactions.map(transaction => transaction[groupBy])
	);
	[...transactionsTitleSet.values()].forEach(title => {
		const savedTransactions = formattedTransactions.map(
			savedTransaction => savedTransaction[groupBy]
		);
		if (!savedTransactions.includes(title)) {
			const transactionsByParam = [];
			transactions.forEach(transaction => {
				if (transaction[groupBy] === title) {
					transactionsByParam.push(transaction);
				}
			});
			formattedTransactions.push({ title, data: transactionsByParam });
		}
	});
	return formattedTransactions;
};

const today = new Date();
const month = today.getMonth() + 1;
export const theYear = today.getFullYear();
export let monthName = '';

switch (month) {
	case 1:
		monthName = 'January';
		break;
	case 2:
		monthName = 'February';
		break;
	case 3:
		monthName = 'March';
		break;
	case 4:
		monthName = 'April';
		break;
	case 5:
		monthName = 'May';
		break;
	case 6:
		monthName = 'June';
		break;
	case 7:
		monthName = 'July';
		break;
	case 8:
		monthName = 'August';
		break;
	case 9:
		monthName = 'September';
		break;
	case 10:
		monthName = 'October';
		break;
	case 11:
		monthName = 'November';
		break;
	case 12:
		monthName = 'December';
		break;
	default:
		monthName = '';
}

export const salesChannelData = [
	{
		key: 'Physical sale',
		value: 'Physical sale',
	},
	{
		key: 'Instagram',
		value: 'Instagram',
	},
	{
		key: 'WhatsApp',
		value: 'WhatsApp',
	},
	{
		key: 'Facebook',
		value: 'Facebook',
	},
	{
		key: 'Twitter',
		value: 'Twitter',
	},
	{
		key: 'Jiji',
		value: 'Jiji',
	},
	{
		key: 'Jumia',
		value: 'Jumia',
	},
	{
		key: 'Paystack',
		value: 'Paystack',
	},
	{
		key: 'Flutterwave',
		value: 'Flutterwave',
	},
	{
		key: 'Konga',
		value: 'Konga',
	},
	{
		key: 'Snapchat',
		value: 'Snapchat',
	},
	{
		key: 'Others',
		value: 'Others',
	},
];

export const phoneRegExp = /^(\+?[0-9] ?(?:[0-9] ?){5,13}[0-9]|\d{11})$/;

export const otherArticles = [
	{
		src: img_benefits,
		title: 'Benefits of Digital Business Management',
		link: constant.Routes.Article1,
	},
	{
		src: img_solution,
		title:
			'Business Management Solutions: Streamlining Operations and Driving Success',
		link: constant.Routes.Article2,
	},
	{
		src: img_everything,
		title:
			'Everything you need to know about the MimaBusiness Pay Bills Feature',
		link: constant.Routes.Article3,
	},
	{
		src: img_payroll,
		title:
			'Streamline your Payroll Management with Mima: The Ultimate Solution for Business Owners',
		link: constant.Routes.Article4,
	},
	{
		src: img_unlock,
		title:
			'Unlock your Business Potential with Mima: The Best Business Management Software',
		link: constant.Routes.Article5,
	},
];

export const getDate = period => {
	switch (period) {
		case 'today': {
			const currentDate = moment().format('YYYY-MM-DD');
			return {
				startDate: currentDate,
				endDate: currentDate,
			};
		}
		case 'thisweek': {
			const startDate = moment().startOf('weeks').format('YYYY-MM-DD');
			const endDate = moment().endOf('weeks').format('YYYY-MM-DD');
			return {
				startDate: startDate,
				endDate: endDate,
			};
		}
		case 'lastweek': {
			const startDate = moment()
				.subtract(1, 'weeks')
				.startOf('weeks')
				.format('YYYY-MM-DD');
			const endDate = moment()
				.subtract(1, 'weeks')
				.endOf('weeks')
				.format('YYYY-MM-DD');
			return {
				startDate: startDate,
				endDate: endDate,
			};
		}
		case 'thismonth': {
			const startDate = moment().startOf('month').format('YYYY-MM-DD');
			const endDate = moment().endOf('month').format('YYYY-MM-DD');
			return {
				startDate: startDate,
				endDate: endDate,
			};
		}
		case 'lastmonth': {
			const startDate = moment()
				.subtract(1, 'months')
				.startOf('months')
				.format('YYYY-MM-DD');
			const endDate = moment()
				.subtract(1, 'months')
				.endOf('months')
				.format('YYYY-MM-DD');
			return {
				startDate: startDate,
				endDate: endDate,
			};
		}
		case 'thisquarter': {
			const startDate = moment().startOf('quarter').format('YYYY-MM-DD');
			const endDate = moment().endOf('quarter').format('YYYY-MM-DD');
			return {
				startDate: startDate,
				endDate: endDate,
			};
		}
		case 'lastquarter': {
			const startDate = moment()
				.subtract(1, 'quarter')
				.startOf('quarter')
				.format('YYYY-MM-DD');
			const endDate = moment()
				.subtract(1, 'quarter')
				.endOf('quarter')
				.format('YYYY-MM-DD');
			return {
				startDate: startDate,
				endDate: endDate,
			};
		}
		case 'thisyear': {
			const startDate = moment().startOf('year').format('YYYY-MM-DD');
			const endDate = moment().format('YYYY-MM-DD');
			return {
				startDate: startDate,
				endDate: endDate,
			};
		}
		case 'lastyear': {
			const startDate = moment()
				.subtract(1, 'year')
				.startOf('year')
				.format('YYYY-MM-DD');
			const endDate = moment()
				.subtract(1, 'year')
				.endOf('year')
				.format('YYYY-MM-DD');
			return {
				startDate: startDate,
				endDate: endDate,
			};
		}
		default: {
			return {
				startDate: '',
				endDate: '',
			};
		}
	}
};

export const filterPeriods = [
	{
		key: 'today',
		value: 'Today',
	},
	{
		key: 'thisweek',
		value: 'This Week',
	},
	{
		key: 'lastweek',
		value: 'Last Week',
	},
	{
		key: 'thismonth',
		value: 'This Month',
	},
	{
		key: 'lastmonth',
		value: 'Last Month',
	},
	{
		key: 'thisquarter',
		value: 'This Quarter',
	},
	{
		key: 'lastquarter',
		value: 'Last Quarter',
	},
	{
		key: 'thisyear',
		value: 'This Year',
	},
	{
		key: 'lastyear',
		value: 'Last Year',
	},
	{
		key: 'custom',
		value: 'Custom (Date Range)',
	},
];

export const encodeQueryData = query => {
	const encodedQuery = Object.entries(query).map(([key, value]) => {
		return `${encodeURIComponent(key)}=${encodeURIComponent(value || '')}`;
	});
	return encodedQuery.join('&');
};

export const getAvailableQuantity = (stock, stockItem) => {
	if (!stock) return 0;
	const matchingStock = stockItem?.find(item => item._id === stock);
	return matchingStock ? matchingStock?.quantity : 50;
};

export const removeEmptyKeys = payload => {
	for (const key in payload) {
		if (payload[key] && typeof payload[key] === 'object') {
			removeEmptyKeys(payload[key]);

			if (Object.keys(payload[key]).length === 0) {
				delete payload[key];
			}
		} else if (
			payload[key] === undefined ||
			payload[key] === null ||
			payload[key] === ''
		) {
			delete payload[key];
		}
	}
};

export const flagData = [
	{
		country: 'Nigeria',
		flag: NgFlag,
		countryCode: 'NG',
		currencyCode: 'NGN',
	},
	{
		country: 'International',
		flag: IntFlag,
		countryCode: 'US',
		currencyCode: 'USD',
	},
];

export const containsHTML = content => {
	return /<\/?[a-z][\s\S]*>/i.test(content);
};

export const stripHtmlTags = html => {
	const tmp = document.createElement('div');
	tmp.innerHTML = html;
	return tmp.textContent || tmp.innerText || '';
};

export const handleImageUpload = async files => {
	const uploadedUrls = [];

	for (let i = 0; i < files.length; i++) {
		const formData = new FormData();
		formData.append('assets', files[i]);

		const { data, status, errorMessage } =
			await StyleCatalogController.uploadDocument(formData);

		if (status === constant.Success) {
			uploadedUrls.push(...data); // Assuming 'data' is an array of URLs as strings
		} else {
			MimaToastUtil.error({ message: errorMessage });
			return constant.Failed;
		}
	}

	return uploadedUrls;
};

export const removeEmptyKeysAndObjects = payload => {
	if (Array.isArray(payload)) {
		// Handle arrays: filter out objects with empty `value` fields
		return payload
			.map(removeEmptyKeysAndObjects) // Recursively process each element
			.filter(
				item =>
					item !== null &&
					item !== undefined &&
					!(Array.isArray(item) && item.length === 0)
			);
	}

	if (typeof payload === 'object' && payload !== null) {
		const newObj = {};

		for (const [key, value] of Object.entries(payload)) {
			const processedValue = removeEmptyKeysAndObjects(value);

			if (key === 'defaultContent' && Array.isArray(processedValue)) {
				// Filter out empty value objects within the `defaultContent` array
				newObj[key] = processedValue.filter(
					item =>
						item.value !== '' && item.value !== undefined && item.value !== null
				);
			} else if (
				processedValue !== null &&
				processedValue !== undefined &&
				!(Array.isArray(processedValue) && processedValue.length === 0)
			) {
				newObj[key] = processedValue;
			}
		}

		return Object.keys(newObj).length > 0 ? newObj : null;
	}

	return payload;
};
